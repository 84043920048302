<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from './components/ComingSoon.vue';

export default {
  name: 'App',
  components: {
    ComingSoon
  }
}
</script>

<style>
#app {
  font-family: 'Courier New', Courier, monospace, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100svh;
  overflow: hidden;
}
</style>
