<template>
<div class="coming-soon">
  <video src="../assets/ComingSoon.webm" :muted="onMobile" autoplay playsinline></video>
  <span>Coming Soon.</span>
</div>
</template>

<script>

export default {
  name: 'ComingSoon',
  

setup() {
    let onMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return {
        onMobile
    };
}
};
</script>

<style>
/*nearly black coming soon page*/
.coming-soon {
  background-color: #000;
  color: rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
video{
  aspect-ratio: 1/1;
  max-width: 80vw;
  max-height: 50svh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
}
span{
  color: transparent;
}
</style>
